import React from 'react'

const EmmetSvg = () => (
  <svg width="260" height="286" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M100 0c30.928 0 56 25.072 56 56v6.166c5.676.953 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.306 11.881-2.066 19.805-14.456 36.54-31.693 44.73L124 163h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4v-18.39c-17.237-8.188-29.628-24.923-31.695-44.729C38.481 99.058 34 94.052 34 88V74c0-5.946 4.325-10.882 10-11.834V56c0-30.928 25.072-56 56-56z"
        id="emmett-a"
      />
      <path
        d="M99 30.348c0 11.863 14.998 21.48 33.5 21.48 18.502 0 33.5-9.617 33.5-21.48 0-.353-.013-.704-.04-1.053 36.976 3.03 66.04 34 66.04 71.757V110H32v-8.948c0-38.1 29.593-69.288 67.047-71.833-.032.376-.047.751-.047 1.129z"
        id="emmett-c"
      />
      <path
        d="M35.118 15.128C36.176 24.62 44.226 32 54 32c9.804 0 17.874-7.426 18.892-16.96.082-.767-.775-2.04-1.85-2.04H37.088c-1.08 0-2.075 1.178-1.97 2.128z"
        id="emmett-e"
      />
      <path id="emmett-g" d="M0 0h264v280H0z" />
      <path
        d="M101.428 98.169c-2.513 2.294-5.19 3.325-8.575 2.604-.582-.124-2.957-4.538-8.853-4.538-5.897 0-8.27 4.414-8.853 4.538-3.385.721-6.062-.31-8.576-2.604-4.725-4.313-8.654-10.26-6.293-16.75 1.23-3.382 3.232-7.095 6.873-8.173 3.887-1.15 9.346-.002 13.264-.788 1.27-.254 2.656-.707 3.585-1.458.929.75 2.316 1.204 3.585 1.458 3.918.786 9.376-.362 13.264.788 3.64 1.078 5.642 4.79 6.873 8.173 2.361 6.49-1.568 12.437-6.294 16.75M140.081 26c-3.41 8.4-2.093 18.858-2.724 27.676-.513 7.167-2.02 17.91-8.384 22.539-3.255 2.367-9.179 6.345-13.431 5.235-2.927-.764-3.24-9.16-7.087-12.303-4.363-3.565-9.812-5.131-15.306-4.89-2.37.105-7.165.08-9.15 1.903-1.983-1.823-6.777-1.798-9.148-1.902-5.494-.242-10.943 1.324-15.306 4.889-3.847 3.143-4.16 11.54-7.087 12.303-4.252 1.11-10.176-2.868-13.431-5.235-6.365-4.63-7.87-15.372-8.384-22.54-.63-8.817.686-19.275-2.724-27.675-1.66 0-.565 16.129-.565 16.129v20.356c.032 15.288 9.581 38.17 30.754 46.908C63.286 111.53 75.015 115 84 115s20.714-3.14 25.892-5.277c21.173-8.737 30.722-31.95 30.754-47.238V42.13S141.74 26 140.081 26"
        id="emmett-i"
      />
      <path
        d="M1 64c.347 1.488 1.673 1.223 2 0-.463-1.554 3.296-28.752 13-36 3.618-2.517 23.008-4.768 42.313-4.754C77.409 23.259 96.42 25.51 100 28c9.704 7.248 13.463 34.446 13 36 .327 1.223 1.653 1.488 2 0 .72-10.298 0-63.726-57-63C1 1.726.28 53.702 1 64z"
        id="emmett-k"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(30 42)">
        <mask id="emmett-b" fill="#fff">
          <use xlinkHref="#emmett-a" />
        </mask>
        <use fill="#D0C6AC" xlinkHref="#emmett-a" />
        <g mask="url(#emmett-b)" fill="#AE5D29">
          <path d="M-32 0h264v244H-32z" />
        </g>
        <path
          d="M44 94c0 30.928 25.072 56 56 56s56-25.072 56-56v8c0 30.928-25.072 56-56 56s-56-25.072-56-56z"
          fillOpacity=".1"
          fill="#000"
          mask="url(#emmett-b)"
        />
      </g>
      <g transform="translate(-2 176)">
        <mask id="emmett-d" fill="#fff">
          <use xlinkHref="#emmett-c" />
        </mask>
        <use fill="#E6E6E6" xlinkHref="#emmett-c" />
        <g mask="url(#emmett-d)" fill="#25557C">
          <path d="M0 0h264v110H0z" />
        </g>
        <g opacity=".6" mask="url(#emmett-d)" fill="#000" fillOpacity=".16">
          <ellipse cx="40.5" cy="27.848" rx="39.635" ry="26.914" transform="translate(92 4)" />
        </g>
      </g>
      <g transform="translate(76 140)">
        <mask id="emmett-f" fill="#fff">
          <use xlinkHref="#emmett-e" />
        </mask>
        <use fillOpacity=".7" fill="#000" xlinkHref="#emmett-e" />
        <path
          d="M44 2h21a5 5 0 015 5v6a5 5 0 01-5 5H44a5 5 0 01-5-5V7a5 5 0 015-5z"
          fill="#FFF"
          mask="url(#emmett-f)"
        />
        <g mask="url(#emmett-f)" fill="#FF4F6D">
          <g transform="translate(38 24)">
            <circle cx="11" cy="11" r="11" />
            <circle cx="21" cy="11" r="11" />
          </g>
        </g>
      </g>
      <path d="M118 136c0 4.418 5.373 8 12 8s12-3.582 12-8" fill="#000" fillOpacity=".16" />
      <g fill="#000" fillOpacity=".6">
        <path d="M90.16 118.447c1.847-3.798 6.004-6.447 10.838-6.447 4.816 0 8.961 2.63 10.817 6.407.552 1.122-.233 2.04-1.024 1.36-2.451-2.107-5.932-3.423-9.793-3.423-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125zM148.16 118.447c1.847-3.798 6.004-6.447 10.838-6.447 4.816 0 8.961 2.63 10.817 6.407.552 1.122-.233 2.04-1.024 1.36-2.451-2.107-5.932-3.423-9.793-3.423-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125z" />
      </g>
      <g fillRule="nonzero" fill="#000" fillOpacity=".6">
        <path d="M89.63 105.159c3.915-5.51 14.648-8.598 23.893-6.328a2 2 0 00.954-3.884c-10.737-2.637-23.165.94-28.107 7.894a2 2 0 003.26 2.318zM170.37 105.159c-3.915-5.51-14.648-8.598-23.893-6.328a2 2 0 01-.954-3.884c10.737-2.637 23.165.94 28.108 7.894a2 2 0 01-3.26 2.318z" />
      </g>
      <g transform="translate(-2 6)">
        <mask id="emmett-h" fill="#fff">
          <use xlinkHref="#emmett-g" />
        </mask>
        <g mask="url(#emmett-h)">
          <g transform="translate(48 72)">
            <mask id="emmett-j" fill="#fff">
              <use xlinkHref="#emmett-i" />
            </mask>
            <use fill="#331B0C" xlinkHref="#emmett-i" />
            <g mask="url(#emmett-j)" fill="#724133">
              <path d="M0 0h168v152H0z" />
            </g>
          </g>
          <g transform="translate(74 34)">
            <mask id="emmett-l" fill="#fff">
              <use xlinkHref="#emmett-k" />
            </mask>
            <use fill="#28354B" xlinkHref="#emmett-k" />
            <g mask="url(#emmett-l)" fill="#4A312C">
              <path d="M-74-34h264v280H-74z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default EmmetSvg
