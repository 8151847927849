import React from 'react'

const DanielSvg = () => (
  <svg width="260" height="286" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M100 0c30.928 0 56 25.072 56 56v6.166c5.676.953 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.306 11.881-2.066 19.805-14.456 36.54-31.693 44.73L124 163h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4v-18.39c-17.237-8.188-29.628-24.923-31.695-44.729C38.481 99.058 34 94.052 34 88V74c0-5.946 4.325-10.882 10-11.834V56c0-30.928 25.072-56 56-56z"
        id="daniel-a"
      />
      <path
        d="M160 29.052c39.765 0 72 32.235 72 72V110H32v-8.948c0-39.765 32.235-72 72-72h1.192a23.41 23.41 0 00-.192 3c0 13.807 12.088 25 27 25s27-11.193 27-25a23.41 23.41 0 00-.192-3H160z"
        id="daniel-c"
      />
      <path
        d="M35.118 15.128C36.176 24.62 44.226 32 54 32c9.804 0 17.874-7.426 18.892-16.96.082-.767-.775-2.04-1.85-2.04H37.088c-1.08 0-2.075 1.178-1.97 2.128z"
        id="daniel-e"
      />
      <path id="daniel-g" d="M0 0h264v280H0z" />
      <path
        d="M64.721 63.464c7.117-1.294 17.075.182 19.386 3.628l-.109-.153.012-.017c2.521-3.324 12.267-4.73 19.264-3.458 9.654 1.757 13.745 12.303 12.506 14.215-1.125 1.736-4.244.095-6.66-.05-5.001-.297-14.172 3.881-23.074-.94a6.042 6.042 0 01-2.048-1.85 6.031 6.031 0 01-2.048 1.85c-8.902 4.821-18.073.643-23.074.94-2.416.145-5.535 1.786-6.66.05-1.24-1.912 2.852-12.458 12.505-14.215z"
        id="daniel-i"
      />
      <path
        d="M11 3c-2.66 8.477.417 24.914-5 25-5.076.071-6-1.006-6-5C0 17.344 4.04 6.778 8 2c1.176-2.17 3.619-3.432 3 1zm104 0c-.619-4.432 1.824-3.17 3-1 3.96 4.778 8 15.344 8 21 0 3.994-.924 5.071-6 5-5.417-.086-2.34-16.523-5-25z"
        id="daniel-k"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(30 42)">
        <mask id="daniel-b" fill="#fff">
          <use xlinkHref="#daniel-a" />
        </mask>
        <use fill="#D0C6AC" xlinkHref="#daniel-a" />
        <g mask="url(#daniel-b)" fill="#614335">
          <path d="M-32 0h264v244H-32z" />
        </g>
        <path
          d="M44 94c0 30.928 25.072 56 56 56s56-25.072 56-56v8c0 30.928-25.072 56-56 56s-56-25.072-56-56z"
          fillOpacity=".1"
          fill="#000"
          mask="url(#daniel-b)"
        />
      </g>
      <g transform="translate(-2 176)">
        <mask id="daniel-d" fill="#fff">
          <use xlinkHref="#daniel-c" />
        </mask>
        <use fill="#E6E6E6" xlinkHref="#daniel-c" />
        <g mask="url(#daniel-d)" fill="#FFF">
          <path d="M0 0h264v110H0z" />
        </g>
        <path
          d="M96 68.5c0 15.21 1.96 29.42 5.362 41.501L32 110v-8.948c0-38.687 30.512-70.248 68.785-71.93C97.74 40.731 96 54.172 96 68.5zm136 32.552V110l-68.362.001C167.039 97.921 169 83.71 169 68.5c0-14.306-1.735-27.73-4.771-39.326C202.025 31.363 232 62.707 232 101.052z"
          fill="#3A4C5A"
        />
        <path d="M181 86l9.556-7.167a4 4 0 014.856.043L202 84l-21 2z" fill="#E6E6E6" />
        <path
          d="M101 28c-4 19.333-2.333 46.667 5 82H90L76 74l6-9-6-6 19-30c2.028-.63 4.028-.964 6-1zM163 28c4 19.333 2.333 46.667-5 82h16l14-36-6-9 6-6-19-30c-2.028-.63-4.028-.964-6-1z"
          fill="#2F4351"
        />
        <path
          d="M156 21.54c6.772 4.596 11 11.116 11 18.348 0 7.4-4.428 14.057-11.48 18.668l-5.941-4.68-4.579.332 1-3.151-.078-.061C152.022 47.853 156 42.7 156 36.876zm-37.922 29.456l-.078.06 1 3.152-4.58-.332-5.94 4.68c-6.92-4.524-11.312-11.018-11.475-18.251L97 39.888c0-7.232 4.228-13.752 11-18.35v15.339c0 5.823 3.978 10.976 10.078 14.119z"
          fill="#F2F2F2"
        />
      </g>
      <g transform="translate(76 140)">
        <mask id="daniel-f" fill="#fff">
          <use xlinkHref="#daniel-e" />
        </mask>
        <use fillOpacity=".7" fill="#000" xlinkHref="#daniel-e" />
        <path
          d="M44 2h21a5 5 0 015 5v6a5 5 0 01-5 5H44a5 5 0 01-5-5V7a5 5 0 015-5z"
          fill="#FFF"
          mask="url(#daniel-f)"
        />
        <g mask="url(#daniel-f)" fill="#FF4F6D">
          <g transform="translate(38 24)">
            <circle cx="11" cy="11" r="11" />
            <circle cx="21" cy="11" r="11" />
          </g>
        </g>
      </g>
      <path d="M118 136c0 4.418 5.373 8 12 8s12-3.582 12-8" fill="#000" fillOpacity=".16" />
      <g fill="#000" fillOpacity=".6">
        <path d="M90.16 118.447c1.847-3.798 6.004-6.447 10.838-6.447 4.816 0 8.961 2.63 10.817 6.407.552 1.122-.233 2.04-1.024 1.36-2.451-2.107-5.932-3.423-9.793-3.423-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125zM148.16 118.447c1.847-3.798 6.004-6.447 10.838-6.447 4.816 0 8.961 2.63 10.817 6.407.552 1.122-.233 2.04-1.024 1.36-2.451-2.107-5.932-3.423-9.793-3.423-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125z" />
      </g>
      <g fill="#E8E1E1">
        <path d="M89.591 102.162c4.496-6.326 14.012-9.508 23.756-6.366a2 2 0 101.228-3.807c-11.408-3.68-22.74.11-28.244 7.856a2 2 0 103.26 2.317zM170.377 109.158c-3.914-5.509-14.647-8.598-23.892-6.328a2 2 0 01-.954-3.884c10.736-2.637 23.165.94 28.107 7.895a2 2 0 11-3.26 2.317z" />
      </g>
      <g transform="translate(-2 6)">
        <mask id="daniel-h" fill="#fff">
          <use xlinkHref="#daniel-g" />
        </mask>
        <g mask="url(#daniel-h)">
          <g transform="translate(48 72)">
            <mask id="daniel-j" fill="#fff">
              <use xlinkHref="#daniel-i" />
            </mask>
            <use fill="#28354B" xlinkHref="#daniel-i" />
            <g mask="url(#daniel-j)" fill="#E8E1E1">
              <path d="M0 0h168v152H0z" />
            </g>
          </g>
          <g transform="translate(69 74)">
            <mask id="daniel-l" fill="#fff">
              <use xlinkHref="#daniel-k" />
            </mask>
            <use fill="#9E7A7A" xlinkHref="#daniel-k" />
            <g mask="url(#daniel-l)" fill="#E8E1E1">
              <path d="M-69-74h264v280H-69z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DanielSvg
