import React from 'react'

const DominikSvg = () => (
  <svg width="260" height="286" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M100 0c30.928 0 56 25.072 56 56v6.166c5.676.953 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.306 11.881-2.066 19.805-14.456 36.54-31.693 44.73L124 163h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4v-18.39c-17.237-8.188-29.628-24.923-31.695-44.729C38.481 99.058 34 94.052 34 88V74c0-5.946 4.325-10.882 10-11.834V56c0-30.928 25.072-56 56-56z"
        id="dominik-a"
      />
      <path
        d="M156.001 13.071C174.101 15.097 188 20.664 188 35.077l-.005-.432C213.853 45.567 232 71.165 232 100.999V110H32v-9c0-29.836 18.147-55.433 44.006-66.356.275-14.093 14.076-19.568 31.994-21.573V32c0 13.255 10.745 24 24 24s24-10.745 24-24z"
        id="dominik-c"
      />
      <path id="dominik-e" d="M0 0h264v280H0z" />
      <path
        d="M121.317 19.373c4.183-1.726 6.255 3.13 10.237 2.511 2.89-.45 3.67-.816 6.277.828 2.53 1.595 3.132.454 5.998.432 2.025-.016 3.265 1.676 4.959 1.853.844.088 1.653-.792 2.56-.737 3.237.197 3.442 4.383 6.014 5.536 2.35 1.053 5.112-.427 7.445-.7 5.69-.668 1.793 4.647 1.954 7.819 2.233.52 4.5-1.564 6.724-1.18 3.502.604 2.18 4.233 1.368 6.68 2.427-.044 8.76-2.737 10.332.707.81 1.776-1.078 4.147-1.047 5.952.043 2.542 1.91 4.645 2.571 7.05.892 3.247 2.163 11.95-.072 14.833-.746.962-5.841-1.74-7.966-2.913-17.845-9.86-21.31-12.995-49.425-12.586-28.116.409-47.379 13.582-48.462 14.93-.754.937-1.716 3.44-2.508 10.413-.791 6.972.22 19.477-2.646 19.462-2.438-.013-2.97-23.786-1.916-33.217.161-1.448.784-4.038.333-5.34-.394-1.138-2.322-1.135-2.014-3.03.35-2.159 2.525-1.087 3.473-2.119 1.795-1.954-.974-2.417-1.433-4.126-.585-2.179.78-2.972 2.301-3.659l.766-.34c1.966-.888 1.71-.109 3.306-1.847-2.131-1.563-2.902-3.691.016-4.833 1.24-.485 4.465.157 5.436-.572.253-.19.353-1.28.51-1.502 1.55-2.219 1.168-3.825 1.01-6.316-.129-2.038-.112-3.909 2.329-4.112 1.628-.136 2.952 1.194 4.411 1.586 1.619.435 1.01.752 2.279-.121 2.226-1.533.711-4.52 2.476-6.303 2.423-2.446 4.01 1.14 5.956 1.41 4.222.588 2.867-3.7 5.367-5.272 2.988-1.876 3.277 2.251 5.143 2.398 1.617.127 4.529-2.993 5.938-3.575zm57.685 51.962c.844-1.037 1.664-.435 2.695-.02 1.115.45 3.937 2.503 4.135 2.614 1.757.988 3.148 10.899 3.216 14.689.042 2.338.08 11.256-2.394 10.485-.753-.235-1.902-4.956-2.066-7.719-.163-2.763-1.733-12.164-4.141-16.49-.55-.988-2.126-2.723-1.445-3.56z"
        id="dominik-g"
      />
      <path
        d="M102 3c8.92 0 16.703 4.866 20.84 12.088.21-.057.431-.088.66-.088h8a2.5 2.5 0 110 5h-6.537A23.998 23.998 0 01126 27c0 13.255-10.745 24-24 24S78 40.255 78 27c0-1.418.123-2.808.359-4.159-.078-4.38-3.442-7.841-7.497-7.841-3.738 0-6.89 2.942-7.42 6.834A24 24 0 0164 27c0 13.255-10.745 24-24 24S16 40.255 16 27c0-2.435.363-4.785 1.037-7H10.5a2.5 2.5 0 110-5h8c.229 0 .45.03.66.088C23.297 7.866 31.08 3 40 3c9.352 0 17.455 5.35 21.416 13.155C63.493 13.04 66.949 11 70.862 11c4.013 0 7.545 2.144 9.603 5.395C84.379 8.459 92.552 3 102 3zM40 7c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20S51.046 7 40 7zm62 0c-11.046 0-20 8.954-20 20s8.954 20 20 20 20-8.954 20-20-8.954-20-20-20z"
        id="dominik-j"
      />
      <filter x="-.8%" y="-2.1%" width="101.6%" height="108.3%" filterUnits="objectBoundingBox" id="dominik-i">
        <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" in="shadowOffsetOuter1" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(30 42)">
        <mask id="dominik-b" fill="#fff">
          <use xlinkHref="#dominik-a" />
        </mask>
        <use fill="#D0C6AC" xlinkHref="#dominik-a" />
        <g mask="url(#dominik-b)" fill="#D08B5B">
          <path d="M-32 0h264v244H-32z" />
        </g>
        <path
          d="M44 94c0 30.928 25.072 56 56 56s56-25.072 56-56v8c0 30.928-25.072 56-56 56s-56-25.072-56-56z"
          fillOpacity=".1"
          fill="#000"
          mask="url(#dominik-b)"
        />
      </g>
      <g transform="translate(-2 176)">
        <mask id="dominik-d" fill="#fff">
          <use xlinkHref="#dominik-c" />
        </mask>
        <use fill="#B7C1DB" xlinkHref="#dominik-c" />
        <g mask="url(#dominik-d)" fill="#FFF">
          <path d="M0 0h264v110H0z" />
        </g>
        <path
          d="M102 61.739V110h-7V58.15a64.54 64.54 0 007 3.589zM169 58.149L169 98.5a3.5 3.5 0 11-7 0l.001-36.762a64.539 64.539 0 007-3.589z"
          fill="#F4F4F4"
          mask="url(#dominik-d)"
        />
        <path
          d="M173.041 12.725c15.05 2.846 25.459 8.518 25.459 19.583C198.5 52.02 165.462 68 132 68S65.5 52.02 65.5 32.308c0-11.065 10.409-16.737 25.459-19.583C81.875 16.075 76 21.706 76 31.077 76 51.469 103.821 68 132 68s56-16.531 56-36.923c0-9.177-5.634-14.767-14.399-18.141z"
          fillOpacity=".16"
          fill="#000"
          mask="url(#dominik-d)"
        />
      </g>
      <g fill="#000">
        <path
          d="M116 156c0 5.372 6.158 9 14 9s14-3.628 14-9c0-1.105-.95-2-2-2-1.293 0-1.87.905-2 2-1.242 2.938-4.317 4.716-10 5-5.683-.284-8.758-2.062-10-5-.13-1.095-.707-2-2-2-1.05 0-2 .895-2 2z"
          fillOpacity=".6"
          fillRule="nonzero"
        />
        <path d="M118 136c0 4.418 5.373 8 12 8s12-3.582 12-8" fillOpacity=".16" />
        <g fillOpacity=".6">
          <path d="M100.998 112c3.504 0 6.893 1.314 9.02 3.543a6.016 6.016 0 011.658 2.506c.05.117.096.237.14.358a2 2 0 01.124.735 6 6 0 11-10.699-2.796c-.08-.002-.162-.002-.243-.002-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125 1.847-3.798 6.004-6.447 10.838-6.447zM158.998 112c3.504 0 6.893 1.314 9.02 3.543a6.016 6.016 0 011.658 2.506c.05.117.096.237.14.358a2 2 0 01.124.735 6 6 0 11-10.699-2.796c-.08-.002-.162-.002-.243-.002-3.74 0-7.124 1.235-9.56 3.228-.891.728-1.818-.014-1.278-1.125 1.847-3.798 6.004-6.447 10.838-6.447z" />
        </g>
        <g fillRule="nonzero" fillOpacity=".6">
          <path d="M89.63 105.159c3.915-5.51 14.648-8.598 23.893-6.328a2 2 0 00.954-3.884c-10.737-2.637-23.165.94-28.107 7.894a2 2 0 003.26 2.318zM170.37 105.159c-3.915-5.51-14.648-8.598-23.893-6.328a2 2 0 01-.954-3.884c10.737-2.637 23.165.94 28.108 7.894a2 2 0 01-3.26 2.318z" />
        </g>
      </g>
      <g transform="translate(-2 6)">
        <mask id="dominik-f" fill="#fff">
          <use xlinkHref="#dominik-e" />
        </mask>
        <g mask="url(#dominik-f)">
          <g>
            <mask id="dominik-h" fill="#fff">
              <use xlinkHref="#dominik-g" />
            </mask>
            <use fill="#2E3257" xlinkHref="#dominik-g" />
            <g mask="url(#dominik-h)" fill="#2C1B18">
              <path d="M0 0h264v280H0z" />
            </g>
          </g>
          <g transform="translate(61 85)">
            <use fill="#000" filter="url(#dominik-i)" xlinkHref="#dominik-j" />
            <use fill="#252C2F" xlinkHref="#dominik-j" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default DominikSvg
