import React from 'react'

const BettySvg = () => (
  <svg width="260" height="286" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M100 0c30.928 0 56 25.072 56 56v6.166c5.676.953 10 5.888 10 11.834v14c0 6.052-4.48 11.058-10.306 11.881-2.066 19.805-14.456 36.54-31.693 44.73L124 163h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4v-18.39c-17.237-8.188-29.628-24.923-31.695-44.729C38.481 99.058 34 94.052 34 88V74c0-5.946 4.325-10.882 10-11.834V56c0-30.928 25.072-56 56-56z"
        id="betty-a"
      />
      <path
        d="M232 101.052V110H32v-8.948c0-35.914 26.294-65.686 60.68-71.116a23.906 23.906 0 007.556 13.603l29.085 26.229a4 4 0 005.358 0l29.085-26.229a24 24 0 001.234-1.196 23.888 23.888 0 006.321-12.404C205.707 35.367 232 65.139 232 101.052z"
        id="betty-c"
      />
      <path id="betty-e" d="M0 0h264v280H0z" />
      <path
        d="M264 0v280h-32v-9c0-39.765-32.235-72-72-72h-4l.001-18.39c17.53-8.328 30.047-25.496 31.79-45.743C193.57 134.003 198 129.02 198 123v-13c0-5.946-4.324-10.881-10-11.834V92c0-30.928-25.072-56-56-56S76 61.072 76 92v6.166c-5.675.952-10 5.888-10 11.834v13c0 6.019 4.431 11.003 10.209 11.867 1.743 20.248 14.26 37.416 31.791 45.744V199h-4c-39.367 0-71.355 31.594-71.99 70.81L32 271v9H0V0h264z"
        id="betty-g"
      />
      <rect id="betty-i" x="0" y="0" width="112" height="150" rx="56" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(32 42)">
        <mask id="betty-b" fill="#fff">
          <use xlinkHref="#betty-a" />
        </mask>
        <use fill="#D0C6AC" xlinkHref="#betty-a" />
        <g mask="url(#betty-b)" fill="#FFDBB4">
          <path d="M-32 0h264v244H-32z" />
        </g>
        <path
          d="M44 94c0 30.928 25.072 56 56 56s56-25.072 56-56v8c0 30.928-25.072 56-56 56s-56-25.072-56-56z"
          fillOpacity=".1"
          fill="#000"
          mask="url(#betty-b)"
        />
      </g>
      <g transform="translate(0 176)">
        <mask id="betty-d" fill="#fff">
          <use xlinkHref="#betty-c" />
        </mask>
        <use fill="#E6E6E6" xlinkHref="#betty-c" />
        <g mask="url(#betty-d)" fill="#FF488E">
          <path d="M0 0h264v110H0z" />
        </g>
      </g>
      <g fill="#000">
        <path
          d="M118 156c0 5.372 6.158 9 14 9s14-3.628 14-9c0-1.105-.95-2-2-2-1.293 0-1.87.905-2 2-1.242 2.938-4.317 4.716-10 5-5.683-.284-8.758-2.062-10-5-.13-1.095-.707-2-2-2-1.05 0-2 .895-2 2z"
          fillOpacity=".6"
          fillRule="nonzero"
        />
        <path d="M120 136c0 4.418 5.373 8 12 8s12-3.582 12-8" fillOpacity=".16" />
        <g transform="translate(76 96)" fillOpacity=".6">
          <circle cx="30" cy="22" r="6" />
          <circle cx="82" cy="22" r="6" />
        </g>
        <g fillRule="nonzero" fillOpacity=".6">
          <path d="M91.63 105.159c3.915-5.51 14.648-8.598 23.893-6.328a2 2 0 00.954-3.884c-10.737-2.637-23.165.94-28.107 7.894a2 2 0 003.26 2.318zM172.37 105.159c-3.915-5.51-14.648-8.598-23.893-6.328a2 2 0 01-.954-3.884c10.737-2.637 23.165.94 28.108 7.894a2 2 0 01-3.26 2.318z" />
        </g>
      </g>
      <g transform="translate(0 6)">
        <mask id="betty-f" fill="#fff">
          <use xlinkHref="#betty-e" />
        </mask>
        <g mask="url(#betty-f)">
          <g>
            <mask id="betty-h" fill="#fff">
              <use xlinkHref="#betty-g" />
            </mask>
            <path
              d="M116.18 40H146c25.405 0 46 20.595 46 46v68.109c0 26.541 18.759 50.24 30.61 71.943 11.851 21.702-7.355 42.19-26.84 50.717A38.509 38.509 0 01180.332 280h-45.068c-56.352 0-65.046-63.69-67.566-133.496l2.52-62.36C71.213 59.48 91.497 40 116.179 40z"
              fill="#CCB55A"
              mask="url(#betty-h)"
            />
          </g>
          <g transform="translate(76 36)">
            <mask id="betty-j" fill="#fff">
              <use xlinkHref="#betty-i" />
            </mask>
            <path
              d="M81.029-12.35c17.057-.974 22.607-16.763 25.82-12.724 12.74 16.01 9.572 34.415-8.048 45.014C107.026 29.483 112 41.91 112 55.5v6c0-15.74-6.672-29.922-17.343-39.87-30.738 11.047-56.642 11.172-77.714.38C6.506 31.935 0 45.957 0 61.5v-6c0-13.608 4.987-26.05 13.233-35.6C9.4 16.862 8.258 8.187 19.467 4.876c11.845-3.499 9.84-18.079 27.99-20.803C65.61-18.65 63.972-11.375 81.03-12.35z"
              fillOpacity=".16"
              fill="#000"
              mask="url(#betty-j)"
            />
          </g>
          <path
            d="M89.878 52.361c22.15 12.866 49.939 12.866 83.366 0 14.53-7.772 25.087-23.319 8.707-43.398-2.169-2.658-10.707 6.713-27.6 7.687-16.893.975-13.271-3.3-31.248-.576-17.977 2.724-15.991 17.304-27.722 20.803-11.73 3.498-9.803 12.987-5.503 15.484z"
            fill="#E0C863"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default BettySvg
