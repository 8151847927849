import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw from 'tailwind.macro'
import config from '../../config/website'
import { colors, screens } from '../../tailwind'

// Components
import Menu from '../components/Menu'
import Layout from '../components/Layout'
import TagList from '../components/TagList'
import PersonaCard from '../components/PersonaCard'
import Footer from '../components/Footer'

// Elements
import {
  BigBox,
  ProjectInner,
  HeaderBigBox,
  BigBoxFull,
  BulletList,
  ImageContainer,
  ImageContainerFull,
  ProjectHeader,
  ProjectDescription,
  ProjectDate,
  ProjectTitle,
  ProjectRow,
  ProjectSection,
  ProjectSectionTitle,
  ProjectSectionText,
  ProjectList,
  ImageGrid,
  ImageCaption,
  ProjectCallout,
} from '../elements/Projects'
import { StatsContainer, Stat, BigNumber, Text } from '../elements/Stats'
import { StyledLinkOut } from '../elements/Links'
import NextProject from '../components/NextProject'

import BettySvg from '../svgs/Betty'
import ChrisSvg from '../svgs/Chris'
import DanielSvg from '../svgs/Daniel'
import DominikSvg from '../svgs/Dominik'
import EmmettSvg from '../svgs/Emmett'

import PakGifOne from '../images/pak/pak-gif-1.gif'
import PakGifTwo from '../images/pak/pak-gif-2.gif'
import PakGifThree from '../images/pak/pak-gif-3.gif'
import TeamGif from '../images/pak/pak-teamphoto-reveal.gif'
import PakDemoVideo from '../videos/search/pak-demo.mp4'

const VideoContainer = styled.div`
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  width: 84%;
  height: 65%;
  transform: translate(-44%, -69%);
`

const Video = styled.video`
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;

  @media (min-width: ${screens.lg}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }

  @media (min-width: ${screens.xl}) {
    grid-template-columns: repeat(3, 1fr);
  }
`

const BroadStrokesGrid = styled(Grid)`
  margin: -50px auto 0;
  grid-auto-rows: minmax(100px, auto);
  align-items: end;

  @media (min-width: ${screens.lg}) {
    margin: auto;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, minmax(50px, auto));
    align-items: start;
  }
`

const CalloutText = styled.div`
  ${tw`text-3xl`};
  flex: 0 0 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: ${props => props.theme.ally};
`

const AccentText = styled.div`
  ${tw`text-xl`};
  color: ${props => props.theme.ally};
`

const theme = {
  menu: colors.white,
  menuHover: colors.black,
  accent: colors['am-green'],
  ally: colors['am-green-ally'],
}

const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
const homeURL = `${config.siteUrl}${realPrefix}`
const image = `${homeURL}/search.jpg`

const Page = ({ data }) => {
  const images = {}
  data.search.images.forEach(({ name, childImageSharp }) => {
    images[name] = childImageSharp
  })

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Helmet>
          <meta property="og:image" content={image} />
        </Helmet>
        <Menu color={colors.white} />
        <HeaderBigBox>
          <ProjectInner>
            <ImageContainer>
              <Img fluid={images.pak.fluid} />
            </ImageContainer>
          </ProjectInner>
        </HeaderBigBox>
        <ProjectInner>
          <ProjectHeader>
            <ProjectDate>Spring 2019</ProjectDate>
            <ProjectTitle>Platform-wide search</ProjectTitle>
            <ProjectDescription>
              Create an experience that can search anything across the platform anytime, anywhere.
            </ProjectDescription>
            <TagList tags={['Research', 'UI', 'UX', 'Software']} />
          </ProjectHeader>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Inception</ProjectSectionTitle>
              <ProjectSectionText>
                <StyledLinkOut
                  href="https://www.ibm.com/analytics/cloud-pak-for-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cloud Pak for Data
                </StyledLinkOut>{' '}
                (CPD) is a platform that brings together several of IBM’s data analytics tools into one end-to-end data
                science workflow. The CPD search experience needed to be built to be 1) flexible enough to extend search
                into future tools but 2) specific enough to provide contextual help on any asset within the platform.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>My contribution</ProjectSectionTitle>
              <ProjectSectionText>
                For this project, I led and presented market, generative, and user-testing research that enabled search
                between seven applications within CPD. I wireframed and designed high-fidelity concepts that I delivered
                to engineers. Much of this work was supported by two other UX designers and one researcher.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection>
              <ProjectSectionTitle>Tools</ProjectSectionTitle>
              <ProjectSectionText>
                <ul>
                  <li>Sketch & Sketch Measure</li>
                  <li>Invision</li>
                  <li>Principal</li>
                  <li>Keynote</li>
                </ul>
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['pak-ibm'].fluid} />
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Understanding the marketplace</ProjectSectionTitle>
              <ProjectSectionText>
                The team started with gaining domain knowledge for the project with competitive and generative research.
                Some of our objectives included the following:
              </ProjectSectionText>
              <ProjectSectionText>
                <ProjectList>
                  <li>
                    Identify how users currently access data and resources (e.g., models), and how well these methods
                    work for them
                  </li>
                  <li>
                    Provide a better understanding of how search fits within a workflow and a user's goal when
                    attempting to find data or resources
                  </li>
                  <li>
                    Identify what information about a search result helps users identify whether it will suit their
                    needs and differentiate between results
                  </li>
                  <li>Understand how permissions fit into data search</li>
                </ProjectList>
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="3.5rem">
              <ProjectSectionText>
                The team conducted one hour interviews with seven professionals (some data scientists and some Chief
                Data Officers) who came from a range of industries. Takeaways from this research are:
              </ProjectSectionText>
              <BulletList>
                <li>There are many limitations with current methods for finding data</li>
                <li>Data scientists want to see models, notebooks, and algorithms</li>
                <li>Users need to trust that the data is safe to use</li>
              </BulletList>
              <ProjectSectionText style={{ marginTop: '2rem' }}>
                Simultaneously, we performed competitive analysis. B2B companies, who are more direct competitors to
                Cloud Pak for Data, helped us understand the technology behind the product. This aided me in speaking
                the same language as our engineering team. We also looked at B2C companies such as Google, Facebook, and
                Wolfram Alpha to understand the quality experience that our users will expect when searching.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Creating a vision</ProjectSectionTitle>
              <ProjectSectionText>
                CPD launched with a very limited search capabilities of the data catalog. Gaps included lack of
                filtering, navigation in the platform, and the ability to search outside of the catalog. Users had to
                look in multiple places to find what they needed to get their work done. The design team created and
                presented a vision to our cross-functional team. The principals we would use as our guiding light were
                as follows:
              </ProjectSectionText>
              <ProjectSectionText>
                <ProjectList>
                  <li>One centralized access point to all resource available anything, anywhere</li>
                  <li>A catalyst for navigation within the platform</li>
                  <li>Search results can be easily connected to their current workflow</li>
                </ProjectList>
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="3.5rem">
              <ProjectSectionText>CPD search was to combine and sort through the following:</ProjectSectionText>
              <BulletList>
                <li>
                  CPD tools and assets that come with a basic install of the platform. These include a data catalog,
                  data analytics and modeling tools, etc.
                </li>
                <li>
                  CPD add-ons which grow in number every quarter and extend the capabilities of the data science
                  workflow.
                </li>
                <li>
                  Company specific catalogs, not created by CPD. These are created by the company that buys and installs
                  CPD.
                </li>
                <li>
                  User generated assets including projects, tasks, and unpublished data (data not reviewed by the
                  company).
                </li>
              </BulletList>
            </ProjectSection>
          </ProjectRow>
          <Grid>
            <div>
              <ProjectSectionTitle>Personas</ProjectSectionTitle>
              <ProjectSectionText>
                One of the most challenging aspects of this project was that CPD is built for several personas who are
                all searching for different assets within the platform. Our team balanced the needs of two focus
                personas (Betty and Chris) and three secondary-focus personas (Daniel, Dominik, and Emmett).
              </ProjectSectionText>
            </div>
            <PersonaCard
              image={<BettySvg />}
              name="Betty"
              job="Business Analyst"
              task="Answer a specific question about data"
              need="Natural Language searching"
              product="CPD"
              capabilities={[
                'Search through data and metadata',
                'Use natural language and see data visualizations',
                'Share answers with colleagues',
              ]}
            />
            <PersonaCard
              image={<ChrisSvg />}
              name="Chris"
              job="Data Scientist"
              task="Gather the right data to build an ML model"
              need="Previewing and accessing data"
              product="CPD search"
              capabilities={[
                'Use conditional filtering',
                'Save search queries',
                'Request access to data',
                'Preview data',
              ]}
            />
            <PersonaCard
              image={<DanielSvg />}
              name="Daniel"
              job="Data Engineer"
              task="Navigate to a past workflow"
              need="Navigating to parts of the platform quickly"
              product="CPD search"
              capabilities={['Navigate to job history', 'Filter and scope search']}
            />
            <PersonaCard
              image={<DominikSvg />}
              name="Dominik"
              job="Data Steward"
              task="View and edit glossary terms and policies"
              need="Cataloging data"
              product="CPD search"
              capabilities={['Search glossary terms and perform actions on them']}
            />
            <PersonaCard
              image={<EmmettSvg />}
              name="Emmett"
              job="System Administrator"
              task="Permission users who enter the platform"
              need="Navigating to parts of the platform quickly"
              product="CPD search"
              capabilities={['Navigate through the platform using search', 'Search through documentation']}
            />
          </Grid>
          <ProjectRow>
            <ProjectSection>
              <ImageContainer style={{ transform: 'translateY(-8%)' }}>
                <Img fluid={images['pak-card-sorting'].fluid} />
              </ImageContainer>
            </ProjectSection>
            <ProjectSection>
              <ProjectSectionTitle>Card sorting</ProjectSectionTitle>
              <ProjectSectionText>
                After our initial round of research, our team started to get a better picture of what our general search
                experience should be. When I started to wireframe concepts, I quickly ran into this question:
              </ProjectSectionText>
              <ProjectCallout>
                What types of results can a user find and what actions can they perform on each result?
              </ProjectCallout>
              <ProjectSectionText>
                This question was a hard one to answer, as the product was less than a year old and still had new IBM
                services being integrated into it every quarter. To move the team forward, I suggested a card sorting
                exercise to get an initial mapping of these searchable results.
              </ProjectSectionText>
              <ProjectSectionText>
                I interviewed several product teams that were associated with the different services being integrated
                into Cloud Pak for Data and drafted a list of results that may appear in the search experience. Once we
                had this list, we split each item into a card and showed it to our Chief Architect. He was able to group
                items in like categories and tell us what items we missed.
              </ProjectSectionText>
              <ProjectSectionText>
                This exercise helped the content of our wireframes become more clear, and we moved forward with
                iterating on concepts.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Designing in broad strokes</ProjectSectionTitle>
              <ProjectSectionText>
                I created several “how might we” statements that helped me to create high level concepts to present to
                our cross functional team.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection />
            <CalloutText>How might we...</CalloutText>
            <BroadStrokesGrid>
              <AccentText>...make data search feel conversational?</AccentText>
              <Img fluid={images['pak-broad-strokes-1'].fluid} />
              <Img fluid={images['pak-broad-strokes-2'].fluid} />
              <AccentText>...make it easier to reuse SQL searches?</AccentText>
              <Img fluid={images['pak-broad-strokes-3'].fluid} />
              <Img fluid={images['pak-broad-strokes-4'].fluid} />
              <AccentText>...provide shortcuts in exploring data?</AccentText>
              <Img fluid={images['pak-broad-strokes-5'].fluid} />
              <Img fluid={images['pak-broad-strokes-6'].fluid} />
            </BroadStrokesGrid>
          </ProjectRow>
        </ProjectInner>
        <ImageContainerFull>
          <Img fluid={images['pak-testing'].fluid} />
        </ImageContainerFull>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>Testing our concept</ProjectSectionTitle>
              <ProjectSectionText>
                Once my team had two main concepts in the works, I led the user testing iniative. I drafted a research
                plan, with the help of my researcher, and we recruited participants to fit our target personas.
              </ProjectSectionText>
              <ProjectSectionText>
                Using Invision, we prompted participants with tasks and had them guide us through each concept. At the
                end of the test, they were able to compare like features to give us more detailed feedback.
              </ProjectSectionText>
            </ProjectSection>
            <ProjectSection mt="3.5rem">
              <ProjectSectionText>Our takeaways were boiled down into these three points:</ProjectSectionText>
              <ProjectSectionText>
                <ProjectList>
                  <li>Speed: “Get me the answer ASAP”</li>
                  <li>Actionable: “I need to do this action with the result I’ve found”</li>
                  <li>Trustworthy: “Promise me the answer is relevant and reviewed”</li>
                </ProjectList>
              </ProjectSectionText>
              <ProjectSectionText>
                These insights acted as our guiding light moving into the next round of prototyping.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
        </ProjectInner>
        <ProjectInner>
          <ProjectRow>
            <ProjectSection>
              <BigBoxFull style={{ display: 'flex', height: '100%' }}>
                <ImageGrid>
                  <img src={PakGifOne} alt="pak-for-data-1" />
                  <img src={PakGifTwo} alt="pak-for-data-2" />
                  <img src={PakGifThree} alt="pak-for-data-3" />
                </ImageGrid>
              </BigBoxFull>
            </ProjectSection>
            <ProjectSection>
              <ProjectSectionTitle>Moving forward</ProjectSectionTitle>
              <ProjectSectionText>
                We took the aspects of our search experience that were validated and improved on the aspects that didn’t
                resonate with our users. Once our search vision was created, we shared it with stakeholders in Offering
                Management, Engineering, and Design to get buy in and moved forward with scoping.
              </ProjectSectionText>
              <ProjectSectionText>
                To the left (or above on mobile) are three interactions I toyed with while prototyping in Principal.
                Here, a user has just performed a keyword search and they are able to view a preview panel with more
                metadata about their result before selecting it.
              </ProjectSectionText>
              <ProjectSectionText>
                As I nailed down the content that would live in these wireframes, I ended up eliminating the preview
                panel all together. Instead, I was able to prioritize the most important information and show it to the
                user on first glance.
              </ProjectSectionText>
            </ProjectSection>
          </ProjectRow>
          <ProjectRow>
            <ProjectSection>
              <ProjectSectionTitle>CPD launches it's search experience</ProjectSectionTitle>
              <ProjectSectionText>
                In Winter 2019 our team delivered and developed an enhanced search experience in CPD. Any user was able
                to search for any asset with contextual filtering to help them with their data science workflow.
              </ProjectSectionText>
              <ProjectSectionText>This project resulted in:</ProjectSectionText>
            </ProjectSection>
            <ProjectSection />
            <StatsContainer
              style={{
                gridTemplateColumns: 'repeat(2, minmax(auto, 330px))',
                gridGap: '3rem',
                margin: 'auto',
              }}
            >
              <Stat>
                <div>
                  <BigNumber>8</BigNumber>
                </div>
                <Text>Search across 8 IBM tools including virtualized data</Text>
              </Stat>
              <Stat>
                <div>
                  <BigNumber>5</BigNumber>
                </div>
                <Text>Target users individual needs met</Text>
              </Stat>
            </StatsContainer>
            {/* <ProjectSectionCentered>
              <Number>8</Number>
              <AccentText>Search across 8 IBM tools including virtualized data</AccentText>
            </ProjectSectionCentered>
            <ProjectSectionCentered>
              <Number>5</Number>
              <AccentText>Target users individual needs met</AccentText>
            </ProjectSectionCentered> */}
          </ProjectRow>
        </ProjectInner>
        <BigBox>
          <ProjectInner>
            <ImageContainer style={{ margin: '4rem auto' }}>
              <Img fluid={images['pak-imac'].fluid} />
              <VideoContainer>
                <Video autoPlay muted loop controls playsinline>
                  <track kind="captions" />
                  <source src={PakDemoVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </Video>
              </VideoContainer>
            </ImageContainer>
          </ProjectInner>
        </BigBox>
        <ProjectInner>
          <ProjectRow>
            <ImageContainer>
              <img src={TeamGif} alt="The Team" />
              <ImageCaption>
                My design team, with some remote team members photoshopped in <span role="img">😊</span>
              </ImageCaption>
            </ImageContainer>
          </ProjectRow>
        </ProjectInner>
        <NextProject to="/tempo" bg={colors['am-blue']} color={colors['am-blue-ally']}>
          Temp°
        </NextProject>
        <Footer />
      </Layout>
    </ThemeProvider>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  {
    search: allFile(filter: { relativePath: { glob: "pak/**/*" } }) {
      images: nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1980) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
